import React from "react"
import SeO from "../components/seo"

function Bulletin(props){

  return(
    <React.Fragment>
      <SeO title="Εγκύκλιος" description="Εγκύκλιος" />      
      <span><a href={process.env.GATSBY_EGKYKLIOS_URL} target="_blank" rel="noopener noreferrer">Λήψη</a> της Εγκυκλίου.</span>
    </React.Fragment>
  );

}

export default Bulletin